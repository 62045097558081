import './language-select-wrapper.css'

const LanguageSelectWrapper = ({t, i18n}: {t: any, i18n: any}) => {

    const handleLanguageChange = (e: any) => {
        i18n.changeLanguage(e.target.dataset.language);
    };

    return (
        <span className='language-select-wrapper'>
            {t("Also available in")}:
            <a className='language-select-link' onClick={handleLanguageChange} data-language={i18n.language ? i18n.language === 'en'? 'ru': 'en' : 'en' }>&nbsp;{i18n.language ? i18n.language === 'en'? 'русский': 'english' : 'english' }</a>
        </span>
    )
}

export default LanguageSelectWrapper

