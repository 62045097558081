import { ReactComponent as EthIcon } from "./ethereum-icon.svg"
import { ReactComponent as ArrowIcon } from "./arrow-icon.svg"
import './nav-bar-network-choise.css'
import EthNetworkIcon from './../../static/images/eth-network-logo.png'
import ArbNetworkIcon from './../../static/images/arb-network.png'
import OptNetworkIcon from './../../static/images/op-network-logo.png'
import MaticNetworkIcon from './../../static/images/matic-network-logo.png'
import BaseNetworkIcon from './../../static/images/base-network-logo.png'
import BnbNetworkIcon from './../../static/images/bsc-network-logo.png'
import AvaxNetworkIcon from './../../static/images/avax-network-logo.png'
import CeloNetworkIcon from './../../static/images/celo-network-logo.png'
import BlastNetworkIcon from './../../static/images/blast-network-logo.png'
import CheckCorrectIcon  from './check-correct-icon.svg'
import ReactHtmlParser from 'html-react-parser'
import parse from 'html-react-parser';
import ReactDOMServer from 'react-dom/server'
import { useState } from "react"
import { headers } from "../.."
import axios from "axios"


const NavBarNetworkChoise = ({ tp, networks, universalProvider, walletConnectModal, dt, setDt, setToTokenData, setFromTokenData, currentNetwork, setCurrentNetwork, setTokens }: { tp: string, networks: any, universalProvider: any, walletConnectModal: any, dt: number, setDt: any, setToTokenData: any, setFromTokenData: any, currentNetwork: any, setCurrentNetwork: any, setTokens: any }) => {
    const openNetworkChoise = (event: any) => {
        var el = event.target;
        el = el.closest('.network-choise-wrapper')
        if (el != null) {
            if (!el.classList.contains('open')) {
                el.classList.add('open')
                setTimeout(() => {window.addEventListener('click', closeNetworkChoise)}, 100)
            }
            else {
                window.removeEventListener('click', closeNetworkChoise)
                el.classList.remove('open')
            }
        }
    }
    const closeNetworkChoise = (event: any) => {
        var el = event.target
        var el1 = null
        if (!el.classList.contains('network-choise-wrapper')) {
            el1 = el.closest('.network-choise-wrapper')
        }
        if (el1 == null || el1 == undefined || el.classList.contains('network-choise-wrapper')) {
            document.querySelectorAll('.network-choise-wrapper.open')[0]?.classList.remove('open')
            document.querySelectorAll('.network-choise-wrapper.open')[1]?.classList.remove('open')
            window.removeEventListener('click', closeNetworkChoise)
        }
        else {
            var el2 = el.closest('.chain-selector-row-container')
            if (el2 != null && el2 != undefined) {
                window.removeEventListener('click', closeNetworkChoise)
                document.querySelectorAll('.network-choise-wrapper.open')[0]?.classList.remove('open')
                document.querySelectorAll('.network-choise-wrapper.open')[1]?.classList.remove('open')
            }
        }
    }
    return (
        <div className={`${tp === 'mobile' ? 'mx-768': 'mn-768'} network-choise`}>
            <div className="network-choise-wrapper">
                <div className="network-choise-popover">
                    <div>
                        <button className="network-select-button" onClick={openNetworkChoise}>
                            <div className="dropdown-selector-menu">
                                <img src={currentNetwork?.logoUrl} />
                                <span className="dropdown-selector-chevron">
                                    <ArrowIcon />
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
                {/* <div className="network-choise-dropdown" data-testid="chain-selector-options"> */}
                <CompileAllNetworks networks={networks} universalProvider={universalProvider} dt={dt} setDt={setDt} currentNetwork={currentNetwork} setCurrentNetwork={setCurrentNetwork} setTokens={setTokens} setToTokenData={setToTokenData} setFromTokenData={setFromTokenData} />
                {/* </div> */}
            </div>
        </div>
    )
}

export default NavBarNetworkChoise;

const CompileAllNetworks = ({networks, universalProvider, dt, setDt, currentNetwork, setCurrentNetwork, setTokens, setToTokenData, setFromTokenData}: {networks: any, universalProvider: any, dt: number, setDt: any, currentNetwork: any, setCurrentNetwork: any, setTokens: any, setToTokenData: any, setFromTokenData: any}) => {
    // console.log(typeof(networks))
    var data: any = []
    networks?.forEach((e: any) => {
        // console.log(e)
        // console.log(typeof(e))
        // data += <div>a</div>
        data.push(<NetworkButtonSelector network={e} currentNetwork={currentNetwork} setCurrentNetwork={setCurrentNetwork} is_selected={e.id==currentNetwork.id} id={Number(e.id)} dt={dt} setDt={setDt} setTokens={setTokens} setToTokenData={setToTokenData} setFromTokenData={setFromTokenData} />)
    })
    return (
        <div className="network-choise-dropdown" data-testid="chain-selector-options">{data}</div>
    )
}

const NetworkButtonSelector = ({network, currentNetwork, setCurrentNetwork, is_selected, id, setDt, dt, setTokens, setToTokenData, setFromTokenData}: {network: any, currentNetwork: any, setCurrentNetwork: any, is_selected: boolean, id: number, dt: number, setDt: any, setTokens: any, setToTokenData: any, setFromTokenData: any}) => {
    const onClickSetNetwork = async (e: any) => {
        const response = await axios.post('https://app.sometestdomain.xyz/api/network', {"chainId": network.chainId}, {headers: headers})
        const respData = await response.data
        setTokens(respData.tokens)
        setToTokenData(null)
        setFromTokenData(respData.tokens[0])
        setCurrentNetwork(network)
    }
    return (
        <button data-testid="Ethereum-selector" onClick={onClickSetNetwork} data-chainId={network.chainId} className={`chain-selector-row-container ${is_selected ? 'selected': ''}`}>
            <img src={network?.logoUrl} alt="" />
            <div className="chain-selector-row-label">
                {network?.name}
            </div>
            <div className="chain-selector-row-status">
                <img src={CheckCorrectIcon} style={{display: `${is_selected? 'block': 'none'}`}} />
            </div>
        </button>
    )
}
