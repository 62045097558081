import './SelectTokenModal.css'
import { ReactComponent as CloseModalSvg } from './close-modal-icon.svg'
import { ReactComponent as PolygonMaticIcon } from './polygon-matic-icon.svg'
import { ReactComponent as NetworkSelectIcon } from './network-select-arrow-icon.svg'
import { ReactComponent as EthereumNetworkIcon } from './eth-network-icon.svg'
import CheckMarkNetworkIcon from './check-mark-network-icon.svg'
import InchLogoicon from './inch-logo.png'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import reactElementToJSXString from 'react-element-to-jsx-string';
import axios from 'axios'
import { headers } from '.'


const SelectTokenModal = ({t, i18n, networks, universalProvider, currentNetwork, setCurrentNetwork, setToTokenData, setFromTokenData, setTokens, tokens, fromTokenData, toTokenData}: {t: any, i18n: any, networks: any, universalProvider: any, currentNetwork: number, setCurrentNetwork: any, setToTokenData: any, setFromTokenData: any, setTokens: any, tokens: any, fromTokenData: any, toTokenData: any}) => {
    const openCloseNetworkChoise = (event: any) => {
        var el = event.target
        if (!el.classList.contains('select-token-network-select-wrapper')) {
            el = el.closest('.select-token-network-select-wrapper')
        }
        if (el?.classList.contains('open')) {
            el?.classList.remove('open')
        }
        else {
            el?.classList.add('open')
        }
    }

    const closeSelectTokenModal = (event: any) => {
        document.querySelector('body')?.classList.remove('select-token-modal-open')
    }

    const searchSelectToken = (event: any) => {
        var select_token_modal = document.querySelector('.select-token-modal')
        var all_tokens = select_token_modal?.querySelectorAll('.token-list-element')
        var val = event.target.value.replaceAll(' ', '').toLowerCase()
        all_tokens?.forEach((e: any) => {
            var el_val = String(e.querySelector('.token-list-element-symbol').textContent).replaceAll(' ', '').replaceAll('\n', '').toLowerCase()
            var el_val2 = String(e.querySelector('.token-list-element-name').textContent).replaceAll(' ', '').replaceAll('\n', '').toLowerCase()
            if (el_val.includes(val) || el_val2.includes(val)) {
                e.style.display = 'flex'
            }
            else {
                e.style.display = 'none'
            }
        })
    }
    return (
        <div>
            <div className='modal-fixed-div'></div>
            <div>
                <div>
                    <div className='select-token-modal' data-token-symbol='' data-select-target='' data-select-blank=''>
                        <div className='select-token-modal-background-wrapper' onClick={closeSelectTokenModal}></div>
                        <div className='select-token-modal-wrapper'>
                            <div className='select-token-modal-overlay'>
                                <div className='select-token-modal-header'>
                                    <div className='select-token-modal-header-section-first'>
                                        <div className='select-token-modal-header-title'>
                                            {t("Select a token")}
                                        </div>
                                        <CloseModalSvg onClick={closeSelectTokenModal} />
                                    </div>
                                    <div className='select-token-modal-header-section-second'>
                                        <input type="text"onInput={searchSelectToken} name="token-search-input" id="token-search-input" placeholder={`${t("Search name or paste address")}`} autoComplete='off' />
                                        <div className='select-token-modal-network-select'>
                                            <div className='select-token-network-select-wrapper' onClick={openCloseNetworkChoise}>
                                                <div className='select-token-network-select-wrapper-second'>
                                                    <div>
                                                        <button className='select-token-network-select-button'>
                                                            <div className='select-token-network-select-button-wrapper'>
                                                                <SelectedNetwork networks={networks} currentNetwork={currentNetwork} />
                                                                {/* <PolygonMaticIcon /> */}
                                                                <span className='select-token-network-select-button-span'>
                                                                    <NetworkSelectIcon />
                                                                </span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                                <ChainSelectorOptions networks={networks} currentNetwork={currentNetwork} setCurrentNetwork={setCurrentNetwork} universalProvider={universalProvider} setToTokenData={setToTokenData} setFromTokenData={setFromTokenData} setTokens={setTokens} />
                                                {/* <div data-testid="chain-selector-options" className='chain-selector-options'>
                                                    <SelectButtonNetwork name='Ethereum' Icon={EthereumNetworkIcon} png_or_svg={false} checked={false} />
                                                    <SelectButtonNetwork name='Arbitrum' Icon={EthereumNetworkIcon} png_or_svg={false} checked={false} />
                                                    <SelectButtonNetwork name='Optimism' Icon={EthereumNetworkIcon} png_or_svg={false} checked={false} />
                                                    <SelectButtonNetwork name='Polygon' Icon={EthereumNetworkIcon} png_or_svg={false} checked={false} />
                                                    <SelectButtonNetwork name='Base' Icon={EthereumNetworkIcon} png_or_svg={false} checked={false} />
                                                    <SelectButtonNetwork name='BNB Chain' Icon={EthereumNetworkIcon} png_or_svg={false} checked={false} />
                                                    <SelectButtonNetwork name='Avalanche' Icon={EthereumNetworkIcon} png_or_svg={false} checked={false} />
                                                    <SelectButtonNetwork name='Celo' Icon={EthereumNetworkIcon} png_or_svg={false} checked={false} />
                                                    <SelectButtonNetwork name='Blast' Icon={EthereumNetworkIcon} png_or_svg={false} checked={false} />
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <SelectTokenModalHeder currentNetwork={currentNetwork} setToTokenData={setToTokenData} setFromTokenData={setFromTokenData} tokens={tokens} setTokens={setTokens} toTokenData={toTokenData} fromTokenData={fromTokenData} />
                                </div>
                                <div className='select-token-modal-separator'></div>
                                <div className='select-token-modal-body'>
                                    <div className='select-token-modal-body-wrapper'>
                                        <div className='select-token-modal-body-list'>
                                            <div className='select-token-modal-body-wrapper'>
                                                <ModalTokenList networks={networks} t={t} setToTokenData={setToTokenData} setFromTokenData={setFromTokenData} currentNetwork={currentNetwork} toTokenData={toTokenData} fromTokenData={fromTokenData} tokens={tokens} />
                                                {/* <div className='modal-token-list'>
                                                    <div className='modal-token-list-title'>
                                                        <div className='modal-token-list-title-text'>
                                                            {t("Popular token")}
                                                        </div>
                                                    </div> */}
                                                    {/* <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} />
                                                    <TokenListElement name='1inch' symbol='1INCH' Icon={InchLogoicon} icon_png_or_svg={true} Network={PolygonMaticIcon} network_png_or_svg={false} /> */}
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-fixed-div'></div>
        </div>
    )
}

export default SelectTokenModal;

const ChainSelectorOptions = ({networks, currentNetwork, setCurrentNetwork, universalProvider, setToTokenData, setFromTokenData, setTokens}: {networks: any, currentNetwork: number, setCurrentNetwork: any, universalProvider: any, setToTokenData: any, setFromTokenData: any, setTokens: any}) => {
    var data: any = []
    networks?.forEach((e: any) => {
        data.push(<SelectButtonNetwork network={e} setCurrentNetwork={setCurrentNetwork} currentNetwork={currentNetwork} universalProvider={universalProvider} networks={networks} setToTokenData={setToTokenData} setFromTokenData={setFromTokenData} setTokens={setTokens} />)
    })
    return (
        <div data-testid="chain-selector-options" className='chain-selector-options'>{data}</div>
    )
}

const SelectedNetwork = ({networks, currentNetwork}: {networks: any, currentNetwork: any}) => {
    var selected_icon = ''
    networks?.forEach((e: any) => {
        if (e.id == currentNetwork.id) {
            selected_icon = e.logoUrl;
        }
    })
    return (<img src={selected_icon} />)
}

const SelectButtonNetwork = ({network, currentNetwork, setCurrentNetwork, universalProvider, networks, setToTokenData, setFromTokenData, setTokens}: {network: any, setCurrentNetwork: any, currentNetwork: any, universalProvider: any, networks: any, setToTokenData: any, setFromTokenData: any, setTokens: any}) => {
    const onCl = async (event: any) => {
        var el = event.target
        if (!el.classList.contains('modal-network-select-row')) {
            el = el.closest('.modal-network-select-row')
        }
        universalProvider.setDefaultChain('eip155:' + el.dataset.chainid)
        const response = await axios.post('https://app.sometestdomain.xyz/api/network', {"chainId": network.chainId}, {headers: headers})
        const respData = await response.data
        setTokens(respData.tokens)
        setToTokenData(null)
        setFromTokenData(respData.tokens[0])
        setCurrentNetwork(network)
    }
    return (
        <button data-testid={`${network.name}-selector`} onClick={onCl} data-chainid={network.chainId} className='modal-network-select-row'>
            <img src={network.logoUrl} />
            <div className='modal-network-select-name-row'>
                {network.name}
            </div>
            <div className='modal-network-select-check-mark'>
                <img src={`${CheckMarkNetworkIcon}`} style={{display:`${network.id==currentNetwork.id? "": 'none'}`}} />
            </div>
        </button>
    )
}

const SelectTokenModalHeder = ({currentNetwork, setToTokenData, setFromTokenData, tokens, setTokens, toTokenData, fromTokenData}: {currentNetwork: any, setToTokenData: any, setFromTokenData: any, tokens: any, setTokens: any, toTokenData: any, fromTokenData: any}) => {
    var data: any = []
    var availableList = ['ETH', 'WETH', 'DAI', 'USDT', 'USDC', 'WBTC', 'BTCB', 'BUSD', 'DAI.e', 'cEUR', 'cUSD']

    tokens?.forEach((el: any) => {
        if (availableList.includes(el.symbol)) {
            data.push(<PopularTokenSelect token={el} Network={currentNetwork?.logoUrl} setToTokenData={setToTokenData} setFromTokenData={setFromTokenData} toTokenData={toTokenData} fromTokenData={fromTokenData} tokens={tokens} />)
        }
    })
    return (
        <div className='select-token-modal-header-section-third'>{data}</div>
    )
}

const PopularTokenSelect = ({token, Network, setToTokenData, setFromTokenData, toTokenData, fromTokenData, tokens}: {token: any, Network: any, setToTokenData: any, setFromTokenData: any, toTokenData: any, fromTokenData: any, tokens: any}) => {
    
    const selectTokenClick = (event: any) => {
        var select_token_modal = document.querySelector('.select-token-modal') as HTMLElement
        var el = event.target
        if (!el.classList.contains('popular-token-select')) {
            el = el.closest('.popular-token-select')
        }
        if (el.dataset.testid != toTokenData?.id && el.dataset.testid != fromTokenData?.id) {
            if (select_token_modal.dataset.selectTarget == 'to') {
                tokens?.forEach((e: any) => {
                    if (e.id == el.dataset.testid) {
                        setToTokenData(e)
                    }
                })
            }
            else {
                tokens?.forEach((e: any) => {
                    if (e.id == el.dataset.testid) {
                        setFromTokenData(e)
                    }
                })
            }
            document.querySelector('body')?.classList.remove('select-token-modal-open')
        }
    }
    
    return (
        <div className={`popular-token-select ${token?.id==toTokenData?.id||token?.id==fromTokenData?.id? 'selected': ''}`} onClick={selectTokenClick} data-testid={token?.id}>
            <div className='popular-token-select-icon-wrapper'>
                <div className='popular-token-select-icons'>
                    <div className='popular-token-select-icon'>
                        <img src={`${token.logoUrl}`} />
                    </div>
                    <div className='popular-token-select-network-icon'>
                        <img src={`${Network}`} />
                    </div>
                </div>
            </div>
            <div className='popular-token-select-symbol-text'>
                {token.symbol}
            </div>
        </div>
    )
}

const ModalTokenList = ({networks, t, setToTokenData, setFromTokenData, currentNetwork, toTokenData, fromTokenData, tokens}: {networks: any, t: any, setToTokenData: any, setFromTokenData: any, currentNetwork: any, toTokenData: any, fromTokenData: any, tokens: any }) => {
    var data: any = []
    var availableList = ['ETH', 'WETH', 'DAI', 'USDT', 'USDC', 'WBTC', 'BTCB', 'BUSD', 'DAI.e', 'cEUR', 'cUSD']
    tokens?.forEach((el: any) => {
        if (!availableList.includes(el.symbol)) {
            data.push(<TokenListElement token={el} Network={currentNetwork?.logoUrl} setToTokenData={setToTokenData} setFromTokenData={setFromTokenData} toTokenData={toTokenData} fromTokenData={fromTokenData} tokens={tokens} />)
        }
    })
    return (
        <div className='modal-token-list'>
            <div className='modal-token-list-title'>
                <div className='modal-token-list-title-text'>
                    {t("Popular token")}
                </div>
            </div>
            {data}
        </div>
    )
}

const TokenListElement = ({token, Network, setToTokenData, setFromTokenData, toTokenData, fromTokenData, tokens}: {token: any, Network: any, setToTokenData: any, setFromTokenData: any, toTokenData: any, fromTokenData: any, tokens: any}) => {

    const selectTokenClick = (event: any) => {
        var select_token_modal = document.querySelector('.select-token-modal') as HTMLElement
        var el = event.target
        if (!el.classList.contains('token-list-element')) {
            el = el.closest('.token-list-element')
        }
        if (el.dataset.testid != toTokenData?.id && el.dataset.testid != fromTokenData?.id) {
            if (select_token_modal.dataset.selectTarget == 'to') {
                tokens?.forEach((e: any) => {
                    if (e.id == el.dataset.testid) {
                        setToTokenData(e)
                    }
                })
            }
            else {
                tokens?.forEach((e: any) => {
                    if (e.id == el.dataset.testid) {
                        setFromTokenData(e)
                    }
                })
            }
            document.querySelector('body')?.classList.remove('select-token-modal-open')
        }
    }

    return (
        <div className={`token-list-element ${token?.id==toTokenData?.id||token?.id==fromTokenData?.id? 'selected': ''}`} data-testid={token?.id} onClick={selectTokenClick}>
            <div className='token-list-element-wrapper'>
                <div className='token-list-element-left-side'>
                    <div className='token-list-element-left-side-wrapper'>
                        <div className='token-list-element-left-side-wrapper-second'>
                            <div className='token-list-element-left-side-token'>
                                <img src={`${token?.logoUrl}`} />
                            </div>
                            <div className='token-list-element-left-side-network'>
                                <img src={`${Network}`} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='token-list-element-center'>
                    <div className='token-list-element-name-wrapper'>
                        <div className='token-list-element-name'>
                            {token.name}
                        </div>
                    </div>
                    <div className='token-list-element-symbol-wrapper'>
                        <div className='token-list-element-symbol'>
                            {token.symbol}
                        </div>
                    </div>
                </div>
                <div className='token-list-element-right-side'>
                    <div className='token-list-element-right-side-wrapper'></div>
                </div>
            </div>
        </div>
    )
}