import NavLogo from './logo-tr.png'
import './nav-bar-logo.css'


const NavBarLogo = () => {
    return (
        <div className="logo">
            <div className="logo-wrapper">
                <img src={NavLogo} width={40} height={40} />
            </div>
        </div>
    )
}

export default NavBarLogo;